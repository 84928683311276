<template>
  <div>
    <div class="project-preview box is-transparent is-shadowless is-radiusless is-clickable" :style="'--bg-color: ' + color ">
      <router-link :to="{
          name: 'project-detail',
          params: {
            slug: project.slug,
            path: project.path
          }
        }"
      >
        <div class="project-preview__content">
          <h4 class="is-capitalized title is-5 has-text-weight-semibold has-text-grey-dark">
            {{ project.name }}
          </h4>
        </div>
      </router-link>
    </div>
  </div>

</template>

<script>
import { computed } from 'vue'
import randomColor from 'randomcolor'

export default {
  props: {
    project: {
      type: Object
    }
  },

  setup(props) {

    const projectLetter = computed(() => props.name.charAt(0))
    const color = randomColor({
      luminosity: 'light',
    })

    return {
      projectLetter,
      color
    }
  }
}
</script>

<style lang="scss">
.project-preview {
  transition: all 550ms ease;
  position: relative;

  &.box {
    border-left: 4px solid var(--bg-color);
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background: var(--bg-color);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    transition: all 500ms ease;
  }

  a {
    position: relative;
    z-index: 1;
    display: block;
  }


  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DDE2FF;
    color: #000;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

}


</style>

<template>
  <Page pageTitle="Projects" customClass="is-projects mt-6" :hasRightBar="false">

    <!-- Row -->
    <!--    <Columns customClass="mt-6">-->
    <!--      <Column>-->
    <!--        <SearchProject v-model.lazy="filterVal" :disabled="loading"/>-->
    <!--      </Column>-->
    <!--    </Columns>-->
    <!-- Row -->

    <!-- Row -->
    <Columns customClass="">
      <Column customClass="">

        <Columns  v-if="loading">
          <Column>
            <!-- No data -->
            <Loading/>
          </Column>
        </Columns>

        <Columns>
          <Column>
            <Box v-if="allProjects" customClass="is-shadowless">
              <table class="table" id="projectTable" style="width: 100%">
                <thead>
                <tr>
                  <th>Projects</th>
                  <th>Issues</th>
                  <th>Deadline</th>
                  <th>Status</th>
                  <th>Updated At</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="project in allProjects" :key="project.id">
                  <td>

                       <router-link :to="{
                              name: 'project-detail',
                              params: {
                                slug: project.slug,
                                path: project.path
                              }
                            }"
                    >


                      <Preview
                          :title="project.name"
                          :updated="project.updated_at"
                      />
                    </router-link>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ project.updated_at }}</td>
                </tr>

                </tbody>
              </table>
            </Box>
          </Column>
        </Columns>

      </Column>
    </Columns>
    <!-- Row -->

    <template #rightbar>
      <h4>sidebar</h4>
    </template>

  </Page>
</template>

<script>
import 'jquery/dist/jquery';
//Datatable Modules
import "@/libs/datatables/jquery.dataTables.min"
import "@/libs/datatables/dataTables.bulma.min"
import "@/libs/datatables/dataTables.bulma.min.css"
import $ from 'jquery';
import Page from '@/components/layout/Page.vue'
// import Section from '../components/ds/Section.vue'
import Columns from '@/components/ds/Columns.vue'
import Column from '@/components/ds/Column.vue'
import Preview from '@/components/projects/listing/preview'
import SearchProject from '@/components/projects/listing/search'
import Loading from '@/components/general/Loading'

import {getProjectIssueStatisticAPI, getProjectsAPI} from '@/use/useGitlabApi'
import {reactive, toRefs, computed, watchEffect} from 'vue'
import {useQuery, useResult} from "@vue/apollo-composable";
import {GET_ALL_PROJECTS} from "@/graphql/queries/projectQuery";
import Box from "@/components/ds/Box";

export default {
  components: {
    Box,
    Page,
    Columns,
    Column,
    Loading,
    Preview,
    // SearchProject
  },
  setup() {
    const {result, loading, error} = useQuery(
        GET_ALL_PROJECTS,
        null,
        {
          clientId: "cmsClient"
        }
    )

    const allProjects = useResult(result, null, data => data.projects)

    // const resp = getProjectsAPI({ per_page: 200, order_by: 'updated_at' })

    const state = reactive({
      filterVal: '',
      offset: 5,
      curpage: 1
    });

    const filtered_projects = computed(() => {
      // let { projects } = resp
      let projects = allProjects.value
      if (state.filterVal === '') {
        return projects
      } else {
        // let filter = filter.value
        return projects.filter(project => project.name.toLowerCase().includes(state.filterVal.toLowerCase()))
      }
    })

    watchEffect(() => {
      if (allProjects.value) {
        // allProjects.value.map(project => {
        //   const {statistics} = getProjectIssueStatisticAPI(project.project_id)
        //   console.log(statistics)
        // })
        // console.log(allProjects.value)
        setTimeout(function () {
          $('#projectTable').DataTable({
            responsive: true,
            order: [[ 4, "desc" ]],
            columnDefs: [
              {
                "targets": [4],
                "visible": false,
              }
            ],
            lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"]],
            pageLength: 5
          });
        }, 100)
      }
    });

    return {
      filtered_projects,
      ...toRefs(state),
      loading,
      allProjects
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  &.table {
    margin-top: 25px !important;

    th {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    td {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
</style>

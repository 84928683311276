<template>
  <Section customClass="loadingState">
    <img
      src="@/assets/img/loading-dog.gif"
      alt=""
    />
    <p class="is-size-6 has-text-weight-bold has-text-link"> gaw gaw...</p>
  </Section>
</template>

<script>
  import Section from '@/components/ds/Section'

  export default {
    name: 'loadingState',
    props: {
      type: {
        type: String,
        default: '0'
      }
    },
    components: {
      Section,
    },
  }
</script>

<style lang="scss" scoped>
  .loadingState {
    text-align: center;
  }
</style>
<template>
  <Box>
      <div class="control has-icons-right">
        <input
            class="input is-large is-white is-shadowless"
            type="text"
            placeholder="Project name"
            :value='modelValue'
            @input='$emit("update:modelValue", $event.target.value)'
            :disabled='disabled'
        >
        <span class="icon is-medium is-right">
          <unicon name="search" fill="royalblue" />
        </span>
      </div>
  </Box>
</template>

<script>
import Box from '@/components/ds/Box.vue'
export default {
  components: {
    Box,
  },
  props: {
    modelValue: String,
    disabled: Boolean
  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

</style>